@import "assets/scss/variables";

.settings-container {
  font-family: Nunito Sans;

  .settings {
    width: 100%;
  }

  .leftcontent-settings {
    padding-right: 0px !important;
  }

  .title-text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 10px !important;
    color: #000000;
  }

  .sub-title-text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #8b8f94;
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .save-button {
    width: max-content !important;
  }

  .account {
    .input-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #8b8f94;
      padding-left: 0px !important;
      margin-bottom: 5px !important;
    }

    .currency-dropdown {
      width: 100% !important;
      background: #fff;
      color: #222;
      border: 1px solid #ced4da;
      text-decoration: none;
      border-radius: 0.25rem;
      padding: 7px;
    }

    .dropdown-menu.show {
      width: 100% !important;
    }
  }

  .my-restaurants {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    .restaurants-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .restaurants-sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      margin-top: 10px;
    }

    .restaurants-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145.5%;
      color: #000000;
    }

    .icon-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 0px;
    }

    .icon {
      margin-left: 20px;
      cursor: pointer;
    }

    .add-restaurant-btn {
      width: 70%;
      margin-left: 30%;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .add-restaurant-btn-icon {
      margin-right: 10px;
    }
  }

  .my-orders {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    .add-restaurant-btn {
      /* width: 70%; */
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .add-restaurant-btn-icon {
      margin-right: 10px;
    }

    .provider-icon {
      width: 100%;
    }

    .provider-dropdown {
      height: 100%;
    }

    .search-container {
      align-self: center;
    }

    .search-icon {
      background-image: url(../../assets//images/icon/SEARCH.svg);
      background-repeat: no-repeat;
      background-position: right 10px bottom 5px;
      padding-right: 40px;
    }

    .my-order-table {
      height: 50vh;
      overflow: auto;
    }

    .my-order-sort {
      width: 100%;
    }

    .my-order-sort-icon {
      max-height: 18px;
    }

    .table-container {
      height: calc(100vh - 400px);
    }

    .text-delivered {
      color: #14c25a !important;
      margin-right: 3px;
    }

    .text-not-delivered {
      color: #ffb11b !important;
      margin-right: 3px;
    }
  }

  .competitive-set {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      margin-top: 10px;
    }

    .restaurants-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145.5%;
      color: #000000;
    }

    .icon-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 0px;
    }

    .icon {
      margin-left: 20px;
      cursor: pointer;
    }

    .competitive-set-btn {
      width: 70%;
      margin-left: 30%;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .competitive-set-btn-icon {
      margin-right: 10px;
    }

    .competitor-sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      margin-top: 10px;
    }

    .competitor-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145.5%;
      color: #000000;
    }

    .competitor-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }
  }

  .my-providers {
    height: calc(100% - 40px);
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    .provider-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .provider-sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      margin-top: 10px;
    }

    .provider-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145.5%;
      color: #000000;
    }

    .icon-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 0px;
    }

    .icon {
      margin-left: 20px;
      cursor: pointer;
    }

    .add-provider-btn {
      width: 70%;
      margin-left: 30%;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .add-provider-btn-icon {
      margin-right: 10px;
    }
  }
}

.add-competitive-table {
  height: 40vh;
  overflow-y: auto;
}

.dropdown-menu.show {
  width: 100%;
}

.custom-dropdown {
  width: 100% !important;
  background: #fff !important;
  color: #222 !important;
  border: 1px solid #ced4da !important;
  text-decoration: none !important;
  border-radius: 0.25rem !important;
  padding: 7px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}

.competitive-modal-footer {
  padding: 0 !important;
  border: 0 !important;
}

.competitive-btn {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.competitive-btn-disabled {
  background: #8b8f94 !important;
  border-radius: 5px !important;
  border-color: #8b8f94 !important;
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.restaurant-dropdown {
  width: 100% !important;
  background: #fff !important;
  color: #222 !important;
  border: 1px solid #ced4da !important;
  text-decoration: none !important;
  border-radius: 0.25rem !important;
  padding: 6px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}

.modal-content {
  background: #f3f4fb !important;
  padding-right: 30px;
  padding-left: 30px;
  padding: 20px;
  // padding-bottom: 50px;
}

.modal {
  z-index: 1051 !important;
}

.add-restaurant-confirm-btn {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.add-restaurants-modal-body {
  padding-left: 40px !important;
  padding-top: 0px !important;
}

.add-restaurants-modal-footer {
  border-top: 0px solid #dee2e6 !important;
}

.type-dropdown-menu {
  height: 200px;
  overflow-y: auto;
}

.type-dropdown-menu-item {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.order-details {
  .container {
    height: 90vh;
    margin-bottom: 0px !important;
    max-width: 100% !important;
  }

  .back {
    width: auto;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #8b8f94;
    cursor: pointer;
  }

  .heading-text {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 46px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .custom-table {
    height: calc(100% - 135px) !important;
  }

  .table-container {
    // height: calc(100% - 130px);
  }

  .text-delivered {
    color: #14c25a !important;
    margin-right: 3px;
  }

  .text-not-delivered {
    color: #ffb11b !important;
    margin-right: 3px;
  }
}

.report-details {
  .report-provider-container {
    height: 100vh;

    .c-t-order-provider {
      // height: calc(100vh - 830px);
    }

    .o-d {
      font-weight: bold;
      font-size: 16px;
      line-height: 29px;

      display: flex;
      align-items: center;

      color: #000000;
    }

    .heading-text {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 34px;
      line-height: 46px;
      display: flex;
      align-items: center;
      color: #000000;
      margin: 0;
    }

    .details {
      height: calc(100vh - 325px);
    }

    .provider-details {
      overflow: auto;
      max-height: 65%;
      border-radius: 20px;
      font-family: Nunito Sans;
      font-style: normal;
      color: #000000;
      background: #f3f4fb;
      padding: 25px;

      h5 {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }

      .p-name {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }

      .p-caption {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #8b8f94;
        margin-bottom: 0.5rem;
      }

      .p-value {
        font-weight: normal;
        font-size: 14px;
        line-height: 145.5%;
        margin-bottom: 1rem;
      }
    }

    .place-order-btn {
      width: 230px;
      height: 42px;
      left: 300px;
      background: $fullsoon;
      border-radius: 5px;
      color: #fff;
      border: 0;
      font-weight: bold;
      font-size: 15px;
      padding: 9px 31px;
    }

    .order-summary {
      font-family: Nunito Sans;
      font-style: normal;
      background: #f3f4fb;
      padding: 2rem 0.75rem;
      height: 97px;
      color: #000000;

      .d-date {
        .caption {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
        }

        .value {}
      }

      .t-order {
        .caption {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }

        .value {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .order-container {
      height: calc(100vh - 25px);
    }

    .dropzone {
      text-align: center;
      padding: 20px;
      color: #bdbdbd;
      margin-bottom: 12px;
      background: rgba(99, 83, 234, 0.1);
      border: 1px dashed #6353ea;
      box-sizing: border-box;
      border-radius: 10px;
      height: 142px;

      p {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #8b8f94;
        padding-top: 40px;
      }
    }

    .report-input-title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      margin-bottom: 5px;
      margin-top: 10px !important;
    }

    .photo-container {
      .files-list {
        li {
          height: 48px;
          background: #ffffff;
          border: 0.5px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 15px;
          padding-top: 6px !important;

          img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            cursor: pointer;
          }

          img.eye {
            width: 15px;
            height: 10px;
          }

          span {
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #000000;

            &.size {
              font-size: 12px;
            }
          }
        }
      }
    }

    textarea {
      width: 100%;
      border-radius: 5px;
    }
  }
}

.add-restaurant {
  .dropzone {
    text-align: center;
    padding: 20px;
    color: #bdbdbd;
    margin-bottom: 12px;
    background: rgba(99, 83, 234, 0.1);
    border: 1px dashed #6353ea;
    box-sizing: border-box;
    border-radius: 10px;
    height: 142px;

    p {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      padding-top: 40px;
    }
  }

  .photo-container {
    .files-list {
      li {
        height: 48px;
        background: #ffffff;
        border: 0.5px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        padding-top: 6px !important;

        img {
          width: 20px;
          height: 20px;
          margin-top: 6px;
          cursor: pointer;
        }

        img.eye {
          width: 15px;
          height: 10px;
        }

        span {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #000000;

          &.size {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.weekdays-dropdown-item {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}

.frequency-label-text {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.frequency-every-input {
  width: 40px;
  font-size: 13px;
}

.form-check-input:checked {
  background-color: $purple !important;
  border-color: $purple !important;
}

.print-report-problem {
  .order-summary {
    height: 200px !important;
  }

}
.c-t-order-provider{
  label.checkbox {
    &:hover {
      color: inherit !important;
    }
  }
}