@import "assets/scss/variables";

.my-ingredients {
  height: calc(100% - 165px);
  .sort-container {
    text-align: end;
    img {
      height: 38px;
      cursor: pointer;
    }
  }
  .table-container {
    height: calc(100vh - 375px);
  }
}
.add-btn-container {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  order: 1;
  flex-grow: 0;
  .add-btn-icon {
    margin-right: 10px;
  }
}

.search-input {
  height: 38px;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 600px) { 
    width: 100% !important; 
  }
}

.allergens-container {
  .allergens-badge {
    background-color: #e0e0e0 !important;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    padding: 9px 27px;
    align-items: center;
    text-align: center;
    color: #8b8f94;
    height: 37px;
    margin-bottom: 13px;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 10px;
    }
    &.active {
      background-color: $purple !important;
      color: $white !important;
    }
  }
}
.add-ingredient {
  .modal-header {
    border-bottom: #ffffff;
  }
  .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }
  }
  .custom-input {
    height: 39px;
    background: #ffffff;
    border: 0.5px solid #e0e0e0 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
    &:focus {
      box-shadow: none;
    }
  }
  .dropzone {
    text-align: center;
    padding: 20px;
    color: #bdbdbd;
    margin-bottom: 12px;
    background: rgba(99, 83, 234, 0.1);
    border: 1px dashed #6353ea;
    box-sizing: border-box;
    border-radius: 10px;
    height: 142px;
    cursor: pointer;
    p {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      padding-top: 40px;
    }
  }

  .photo-container {
    .files-list {
      li {
        height: 48px;
        background: #ffffff;
        border: 0.5px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        padding-top: 6px !important;
        img {
          width: 20px;
          height: 20px;
          margin-top: 6px;
          cursor: pointer;
        }
        img.eye {
          width: 15px;
          height: 10px;
        }
        span {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #000000;
          &.size {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.sort-filter {
  button {
    height: 38px;
  }
}
.customSelect {
  height: 33px !important;
}

.page-item.active .page-link {
  background-color: #6353ea !important;
}

.add-ingredient-backdrop {
  z-index: 1051 !important;
}

.deleteImage {
  width: 35px;
  position: absolute;
  right: -20px;
  top: -15px;
  cursor: pointer;
}

.import-table {
  table-layout: auto !important;
  tr {
     td.select {
      border: 1px solid rgba(0, 0, 0, 0.06) !important;
      max-width: 22%;
      white-space: nowrap;
    }
     td.input {
      border: 1px solid rgba(0, 0, 0, 0.06) !important;
      max-width: 22%;
      width: 50%;
      white-space: nowrap;
    }
    th {
      border: 1px solid rgba(0, 0, 0, 0.06) !important;
      background: #d9d9d9 !important;
      max-width: 100%;
      white-space: nowrap;

      .simple-anchor {
        text-decoration: none;
        color: black;
      }
    }
  }
}

.imported-table-container {
  max-height: 55vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 40px;
    background-color: #eeeeee;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    width: 6px;
    height: 6px;
    border-radius: 40px;
  }
}

@media screen and (max-width: 600px) { 
  .co2-reducer-details {
    display: flex !important; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }

  .reducer-impact {
    display: flex !important; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin-top: 35px !important;
  }
  .reducer-impact-card{
    width: 100% !important;
  }

  .drq-details{
    width: 100% !important;
  }
}


@media screen and (max-width: 600px) {
  .ingredient-card-button {
    width: 100% !important; 
    transform: translate(0%, -54%) !important;
    margin-left: 0px !important;
  }
}
