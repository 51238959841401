.cardlist {
  p {
    margin: 0;
  }
  .media {
    margin-bottom: 10px;
  }
  .boxicon {
    background-color: $lightgray;
    border-radius: 6px;
    width: 54px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    display: block;
    margin-right: 8px;
  }
}

.card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  @include transition(all 0.31s ease);
  border: 0;
  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  .card-body {
    padding: 16px;
  }
  .card-header {
    box-shadow: none !important;
    background: $white;
    margin: 0;
    padding: 20px 20px 0;
    border: 0;

    .btn {
      border: 1px solid $gray;
      border-radius: 6px;
    }
  }
  .table {
    thead {
      border: none;
      tr {
        border: none;
        th {
          border: none;
        }
      }
    }
    tbody {
      border: none;
    }
    tr {
      border: none;
      td {
        border: none;
        font-size: 14px;
      }
    }
  }
}
.progress {
  height: 6px;
  border-radius: 6px;
  .progress-bar {
    border-radius: 6px;
  }
}
.progresscard {
  .progressitem {
    p {
      margin: 0;
    }
  }
}
.righside1 {
  .weathercard {
    margin-bottom: 20px;
    border: 0;
    border-radius: 10px;
    .card-body {
      padding: 12px 20px;
    }
    ul.list {
      li {
        padding: 3px 0;
        &:last-child {
          padding-bottom: 0;
        }
        strong,
        span {
          font-size: 16px;
          font-weight: bold;
          flex: 1 0 0%;
        }
      }
    }
  }
  .eventCard {
    min-height: 100px;
  }
}
.table > :not(caption) > * > * {
  border: none;
  padding: 0.4rem 0.5rem;
}

strong {
  font-weight: bold;
}

.navbtns {
  margin-bottom: 20px;
  display: flex;
  /* padding-right: 20px; */
  li {
    color: #8B8F94;
    
    flex: 1 1 auto;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
    &:first-child {
      /* margin-right: 10px; */
    }
    &.active {
      border-bottom: 2px solid $fullsoon !important;
    }
    a, button {
      font-size: 12px;
      font-weight: bold;
      border: 0;
      padding: 10px 30px;
      background-color: transparent;
      /* background-color: #f1f3f4 !important; */
      color: $gray;
      &.active {
        /* background-color: $primary !important; */
        color: #000;
      }
    }
  }
  .nav-link {
    color: inherit;
  }
}
