@import "../assets/scss/variables";

$sidebar-bg-color: #fff !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

.toggleIcon {
  position: absolute;
  left: 225px;
  z-index: 1050;
  top: 38px;
  width: 25px;
  cursor: pointer;
  &.collapsed {
    left: 88px;
    transform: rotate(180deg);
  }
}

.pro-sidebar {
  height: 100vh !important;
  .pro-menu {
    padding: 0 !important;
    .pro-menu-item > .pro-inner-item {
      padding: 0 !important;
    }
  }
  .pro-sidebar-layout {
    &::-webkit-scrollbar-track {
      border-radius: 50px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: #e0e0e0;
    }
  }
}
