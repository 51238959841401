.delete-modal {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 29px;
    color: #000000;
  }
  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .icon-conatiner {
    display: flex;
    justify-content: flex-end;
  }
  .icon {
      height: 35px;
      cursor: pointer;
  }
  .back-btn:focus,
  .back-btn:hover {
    background: #6353ea !important;
    border-color: #6353ea !important;
    color: #fff;
  }

  .positive-btn{
    padding-right: 40px;
    padding-left: 40px;
  }
}
