
.account {
  .row {
    @media screen and (max-width: 600px) {
      margin: 0;
    }
  }
  .saveChanges{
    margin-top: 20px;
  }
}
