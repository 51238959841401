.signup-container {
  min-height: 100vh !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: white;

  .left-container {
    background-color: #6353ea;

    .align-middle {
      align-self: center;
    }

    .left-container-logo{
      align-self: center;
      margin-left: 20px;;
    }

    .logo-text {
      // font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: white;
      margin-left: 8px;
    }
  }

  .right-container {
    .back-text {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #8b8f94;
    }

    .sign-up-title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 34px;
      line-height: 46px;
      display: flex;
      align-items: center;
      color: #000000;
      margin-top: 50px;
      margin-bottom: 30px !important;
      @media (max-width:1400px) {
        margin-top: 20px;
        margin-bottom: 10px !important;
      }
    }

    .input-title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #8b8f94;
    }

    .signup-btn {
      margin-top: 40px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      width: 100%;
      @media (max-width:1400px) {
        margin-top: 20px;
      }
    }

    .error-text {
      font-family: Nunito Sans;
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: red;
      margin-top: 5px;
    }

    .phone-input{
      width: 100% !important;
    }

    .sign-up-password-container {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      // flex-direction: row-reverse;
    }

    .sign-up-password-icon {
      position: absolute;
      padding-right: 10px;
      height: 18px;
      cursor: pointer;
    }
  }
}
