.btn.btn-primary {
  background: $primary;
  border: 1px solid $primary;
  color: $white;
  &:focus,
  &:hover {
    background: darken($primary, 5%);
    border: 1px solid $primary;
    box-shadow: 0 14px 26px -12px rgba(0, 105, 168, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 105, 168, 0.2);
  }
}
.btn.btn-danger {
  background-color: $alert;
  border: 1px solid $alert;
  &:focus,
  &:hover {
    background: darken($alert, 5%);
    border: 1px solid $alert;
  }
}
.btn.btn-outline-primary {
  color: $brand-primary;
  border-color: $brand-primary;
  &:focus,
  &:hover {
    background: darken($brand-primary, 10%);
    border-color: darken($brand-primary, 10%);
  }
}
.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: darken($brand-primary, 10%);
  border-color: darken($brand-primary, 20%);
}
.btn.btn-success {
  background: $brand-success;
  border: 1px solid $brand-success;
  color: $white;
  &:focus,
  &:hover {
    background: darken($brand-success, 5%);
  }
}
.btn.btn-secondary {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn.btn-secondary:hover {
  color: $white;
  background-color: darken($dark, 10%);
  border-color: darken($dark, 10%);
}
.btn.btn-info {
  background: #e4ecfe;
  color: $primary;
  border-color: #ccdcff;
  &:hover {
    background: $primary;
    color: $white;
    border-color: $primary;
  }
}
.btn.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: darken($primary, 5%);
  border-color: darken($primary, 5%);
}
.btn.btn-info1 {
  background: #e4ecfe;
  color: $primary;
  border-color: #b8c8ee;
  &:hover {
    background: #e4ecfe;
    color: $primary;
    border-color: $primary;
  }
}
.btn.btn-round {
  border-radius: 50px;
}
.btn.btn-white {
  background: #fff;
  color: #222;
  border: 1px solid #eee;
  text-decoration: none;
}
.btn.btn-transparent {
  background: transparent;
  color: #222;
  text-decoration: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
