.verify-email-container {
  min-height: 100vh !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: white;
  font-family: Nunito Sans;

  .left-container {
    background-color: #6353ea;

    .align-middle {
      align-self: center;
    }

    .left-container-logo {
      align-self: center;
      margin-left: 20px;
    }

    .logo-text {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: white;
      margin-left: 8px;
    }
  }

  .right-container {
    .signin-btn {
      margin-top: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      width: 100%;
      @media (max-width: 1400px) {
        margin-top: 30px;
      }
    }

    .verification-title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      // line-height: 19px;
      text-align: start;
      color: #6353ea;
      margin-bottom: 40px;
    }

    .input-title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #8b8f94;
    }
  }
}
