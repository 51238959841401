/* CustomSelect.scss */

.custom-select {
    select {
      background-color: transparent;
      border: none;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar {
        width: 4px !important;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(170, 169, 169);
        min-height: 40px;
      }
    }
  }