// center vertically and/or horizontally an absolute positioned element
// @mixin flexcenter{
// 	@include flexbox();
// 	@include align-items($center);
// 	@include justify-content($center);
// }
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}
@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
@mixin box_shadow_1dp {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 1px -2px rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
@mixin box_shadow_2dp {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
@mixin box_shadow_3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
@mixin box_shadow_4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
@mixin overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
@mixin skew {
  padding: 0;
  background: #ffffff;
  content: "";
  display: block;
  height: 25px;
  position: relative;
  top: -20px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transform: skewY(-2.7deg);
  outline: 4px solid transparent;
  transform-origin: 100%;
}
@mixin center($xy: xy) {
  @if $xy == xy {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  } @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  } @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}
@mixin card {
  @include transition;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 320px;
  height: 250px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 25px;
  margin-bottom: 50px;
  .card-title {
    font-size: 22px;
    line-height: 1.7;
    color: #272a2e;
    margin: 0 0 10px;
    font-family: $heading-font;
  }
  span {
    background: rgba(255, 255, 255, 0);
    color: #3681ee;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 0;
  }
  ul {
    margin-bottom: 15px;
    li {
      margin-left: 5px;
      font-size: 20px;
      line-height: 1.4;
      color: #747880;
    }
  }
  &:hover {
    -webkit-transform: translate(0, -3px);
    -moz-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    -o-transform: translate(0, -3px);
  }
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root("::-webkit-input-placeholder") {
    @content;
  }

  @include optional-at-root(":-moz-placeholder") {
    @content;
  }

  @include optional-at-root("::-moz-placeholder") {
    @content;
  }

  @include optional-at-root(":-ms-input-placeholder") {
    @content;
  }
}
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
// This can be stored globally and can be pulled in by the mixin

$breakpoints: (
  "tiny": (
    max-width: 575px,
  ),
  "small": (
    max-width: 767px,
  ),
  "medium": (
    max-width: 991px,
  ),
  "large": (
    max-width: 1199px,
  ),
); // Creating the mixin
@mixin breakpoint($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  } @else {
    @warn "Couldn 't find a your breakpoint named `#{$name}`.";
  }
}

@mixin font100 {
  font-family: "Bebas Neue";
  font-weight: 200;
}
@mixin font200 {
  font-family: "Bebas Neue";
  font-weight: 200;
}
@mixin font300 {
  font-family: "Bebas Neue";
  font-weight: 300;
}
@mixin font400 {
  font-family: "Bebas Neue";
  font-weight: 400;
}
@mixin font700 {
  font-family: "Bebas Neue";
  font-weight: bold;
}
$prefix: "-moz-", "-webkit-", "-o-", "-ms-", "";
// ********************
// fadeIn
// ********************
@mixin keyframe-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-webkit-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-o-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@-ms-keyframes fadeIn {
  @include keyframe-fadeIn;
}
@keyframes fadeIn {
  @include keyframe-fadeIn;
}
@mixin fadeIn($arg) {
  $keyframe-name: fadeIn;
  $duration: $arg;
  @each $p in $prefix {
    #{$p}animation: $keyframe-name $duration;
  }
}
