@import "assets/scss/variables";

.custom-width {
  max-width: 90vw !important;
  max-height: 860px !important;
}

.input-field {
  height: 43px !important;
  border-radius: 10px !important;
  border: 0.1px solid #e0e0e0 !important;
  &:focus {
    box-shadow: none;
  }
}

.rest-model-width {
  width: 1212px !important;
  height: 574px !important;
}

.css-1dimb5e-singleValue {
  white-space: normal !important;
}

.country-of-origin {
  overflow: inherit !important;
}

.css-1nmdiq5-menu {
  z-index: 999999999999999999 !important;
  background-color: white;
}

.css-5736gi-menu {
  z-index: 999999999999999999 !important;
  background-color: white;
}

div[role="listbox"] {
  overflow-x: hidden !important;
}

.download-icon-container {
  margin-bottom: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 50% !important;
  width: max-content;
  max-width: 80%;
  box-shadow: 0px 1px 4px 0.2px rgba(0, 0, 0, 0.3) !important;
  -webkit-box-shadow: 0px 1px 4px 0.2px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0px 1px 4px 0.2px rgba(0, 0, 0, 0.3) !important;

  &:hover {
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 1) !important;
  -webkit-box-shadow: 0px 1px 4px 0.2px rgba(0, 0, 0, 1) !important;
  -moz-box-shadow: 0px 1px 4px 0.2px rgba(0, 0, 0, 1) !important;
  }
}
 
.weight-container {
  border: 1px solid red;
  border-radius: 30px;
  padding: 2px 11px;
}

.CE-icon {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
  // fill: linear-gradient(90deg, #65a700 0%, #a4f500 25%, #ffc300 50%, #ff1d00 75%, #890500 100%);
}

.add-btn-container[disabled]:hover .tooltip {
  display: block; 
}

.table-row-wrapper {
  border: 1px;
  border-style: solid;
  border-color: #c3bcbc;
  border-radius: 28px;
  min-width: 369%;
  margin-bottom: 20px;
}
.table-row { 
  display: flex !important; 
  justify-content: space-between;
  align-items: center;
  margin: 0px !important; 
  padding: 0px 14px !important; 
  min-width: 100% !important
}

.coeq-container {
  .coeq-card {
    border-radius: 10px;

    .card-head {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 15%;
      border-bottom: 1px solid #e0e0e0;

      .card-heading {
        font-size: 25px;
        font-weight: bolder;
      }
    }
    .card-body {
      padding-left: 4%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .progress-item {
        height: 21%;
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        p {
          margin-bottom: 5px;
          color: #8b8f94;
        }

        .progress-container {
          display: flex;
          gap: 10px;
        }

        span {
          color: #8b8f94;
          font-size: 10px;
        }

        .progress-bar {
          width: 90%;
          height: 20px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: #d9d9d9;

          .progress-value {
            height: inherit;
            border-radius: inherit;
            background-color: #6353ea;
            text-align: end;
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

// styling
.my-ingredients {
  height: calc(100% - 165px);
  .sort-container {
    text-align: end;
    img {
      height: 38px;
      cursor: pointer;
    }
  }
  .table-container {
    height: calc(100vh - 375px);
  }
}
.button-container {
  .add-btn-container {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    min-width: "max-content";
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    display: flex;
    align-items: center;
    order: 1;
    flex-grow: 0;
    .add-btn-icon {
      margin-right: 10px;
    }
  }
}

.search-input {
  height: 38px;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;
  &:focus-visible {
    outline: none;
  }
}

.allergens-container {
  .allergens-badge {
    background-color: #e0e0e0 !important;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    padding: 9px 27px;
    align-items: center;
    text-align: center;
    color: #8b8f94;
    height: 37px;
    margin-bottom: 13px;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 10px;
    }
    &.active {
      background-color: $purple !important;
      color: $white !important;
    }
  }
}
.add-ingredient {
  .modal-header {
    border-bottom: #ffffff;
  }
  .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }
  }
  .custom-input {
    height: 39px;
    background: #ffffff;
    border: 0.5px solid #e0e0e0 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
    &:focus {
      box-shadow: none;
    }
  }
  .dropzone {
    text-align: center;
    padding: 20px;
    color: #bdbdbd;
    margin-bottom: 12px;
    background: rgba(99, 83, 234, 0.1);
    border: 1px dashed #6353ea;
    box-sizing: border-box;
    border-radius: 10px;
    height: 142px;
    cursor: pointer;
    p {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #8b8f94;
      padding-top: 40px;
    }
  }

  .photo-container {
    .files-list {
      li {
        height: 48px;
        background: #ffffff;
        border: 0.5px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        padding-top: 6px !important;
        img {
          width: 20px;
          height: 20px;
          margin-top: 6px;
          cursor: pointer;
        }
        img.eye {
          width: 15px;
          height: 10px;
        }
        span {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #000000;
          &.size {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.meal-model-form {
  .meal-name {
    width: 280px;
  }
}

.sort-filter {
  button {
    height: 38px;
  }
}
.customSelect {
  height: 33px !important;
}

.page-item.active .page-link {
  background-color: #6353ea !important;
}

.add-ingredient-backdrop {
  z-index: 1051 !important;
}

.deleteImage {
  width: 35px;
  position: absolute;
  right: -20px;
  top: -15px;
  cursor: pointer;
}

.import-table {
  table-layout: auto !important;
  tr {
    td.select {
      border: 1px solid rgba(0, 0, 0, 0.06) !important;
      max-width: 22%;
      white-space: nowrap;
    }
    td.input {
      border: 1px solid rgba(0, 0, 0, 0.06) !important;
      max-width: 22%;
      width: 50%;
      white-space: nowrap;
    }
    th {
      border: 1px solid rgba(0, 0, 0, 0.06) !important;
      background: #d9d9d9 !important;
      max-width: 100%;
      white-space: nowrap;

      .simple-anchor {
        text-decoration: none;
        color: black;
      }
    }
  }
}

.add-restaurants-label-title {
  margin-top: 0px !important;
  margin-bottom: 25px !important;
}

td > .custom-select {
  width: max-content !important;
  margin: auto !important;
}

.imported-table-container {
  max-height: 55vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 40px;
    background-color: #eeeeee;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    width: 6px;
    height: 6px;
    border-radius: 40px;
  }
}

.css-13cymwt-control {
  border-radius: 10px !important;
  border: 0.1px solid #e0e0e0 !important;
  height: 43px !important;
  &:focus {
    box-shadow: none;
  }
}

.dashboardButton {
  padding: 25px 0px;
  display: flex;
  justify-content: flex-end;
  button {
    width: 245px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.dashboard-modal {
  @media screen and (max-width: 600px) {
    .modal-content {
      padding: 10px;
      .modal-body {
        padding: 0px;
      }
      .allergens-container {
        padding: 0px 15px 15px !important;
      }

      .custom-table .tablescroll .table td {
        padding: 0.2rem 0.4rem !important;
      }
    }
  }
}

.maincontent {
  .dashboard  {
    .dashboard-table {
      @media screen and (max-width: 600px) {
        margin-top: 20px !important;
        padding: 0px;
        .col-auto {
          width: 50%;
          button {
            width: 100%;
          }
        }
        .sort-container {
          margin-top: 15px;
        }
      }
    }
  }
 
}
