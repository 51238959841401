.custom-table {
  .tablescroll {
    height: inherit;
  }
  .tablescroll::-webkit-scrollbar {
    width: 6px;
  }

  .tablescroll::-webkit-scrollbar-track {
    background: #eee;
  }
  .dashboardHotelCode {
    div {
      float: left;
    }
  }

  .tablescroll {
    /* height: 500px; */
    overflow-y: auto;
    width: 100%;
    /* max-height: 400px; */
    /* max-height: 250px; */
    overflow: auto;
    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      outline: 0;
    }
    .table {
      // background-color: #fff;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0 17px;
      padding: 0px 20px;
      // table-layout: fixed;
      .form-check-input {
        cursor: pointer;
        border-radius: 2px;
        width: 9px;
        height: 9px;
        color: #000000;
        &:focus {
          border-color: rgba(0, 0, 0, 0.25);
          box-shadow: none;
        }
        &:checked {
          background-color: #6353ea;
          border-color: transparent;
        }
      }
      td {
        padding: 0.7rem 0.75rem;
        vertical-align: middle;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        font-size: 1rem;
        color: #333;
        font-weight: 400;
        .input-style {
          width: 100%;
          background: #ffffff;
          border: 0.3px solid #8b8f94;
          box-sizing: border-box;
          text-align: center;
          padding: 4px;
          height: 27px;
        }
        #custom-switch-check {
          cursor: pointer;
          border-radius: 10px;
          width: 39px;
          height: 19px;
          &:focus {
            border-color: rgba(0, 0, 0, 0.25);
            box-shadow: none;
          }
          &:checked {
            background-color: #6353ea;
            border-color: transparent;
          }
        }
      }
      tbody {
        .selected-tr {
          background-color: #cad1ff !important;
        }

        .less_qty-tr {
          background-color: #f8d2d9 !important;
        }

        tr {
          background-color: white;
          border-radius: 37px; 
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
          td:first-child {
            border-top-left-radius: 37px;
            border-bottom-left-radius: 37px;
          }
          td:last-child {
            border-top-right-radius: 37px;
            border-bottom-right-radius: 37px;
          }

          td {
            border: none;
            overflow: unset;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:first-child {
              position: sticky;
              left: 0;
              background-color: white;
              z-index: 2; 
            }
          }
          td, th {
            padding: 15px;
          }

          &:nth-of-type(even) {
            background-color: white;
            td {
              &:first-child {
                background-color: white;
              }
            }
          }
        }
        .selected-tr {
          td {
            &:first-child {
              background-color: #cad1ff !important;
            }
          }
          &:nth-of-type(even) {
            background-color: #cad1ff !important;
            td {
              &:first-child {
                background-color: #cad1ff !important;
              }
            }
          }
        }
        .less_qty-tr {
          td {
            &:first-child {
              background-color: #f8d2d9 !important;
            }
          }
          &:nth-of-type(even) {
            background-color: #f8d2d9 !important;
            td {
              &:first-child {
                background-color: #f8d2d9 !important;
              }
            }
          }
        }
      }
      thead {
        background-color: #fff;
        position: sticky !important; 
        z-index: 99;
        top: 0;
        th {
          padding: 1rem 0.75rem;
          width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 1440px) {
            width: 85px;
          }
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
          }
        }
      }

      .table-delete-btn {
        background: transparent;
        border: none;
      }
      .dropdown-menu-custom {
        /* inset: unset !important;
        transform: unset !important; */
        width: initial;
      }
      .dropdown-toggle {
        height: 27px;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 40px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      width: 6px;
      height: 6px;
      border-radius: 40px;
    }
  }
}

.lowStockColor {
  color: #ff8692 !important;
}
